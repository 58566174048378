.navbar {
  background: rgba(255, 255, 255, 0.57);
  box-shadow: 0px -20px 40px 0px rgba(0, 0, 0, 0.25);
  height: 2.9375rem;
  width: 100%;
  display: flex;
  justify-content: space-between;

  &__prompt {
    display: flex;
    flex-direction: row;
    padding-left: 2rem;
    align-items: center;
    gap: 1rem;
  }

  &__search {
    color: rgba(0, 0, 0, 0.5);
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__mic {
    width: 1rem;
  }

}
