.system {
  display: flex;
  align-items: center;
  gap: 1rem;

  &__logo {
    width: 2rem;
    height: 2rem;
    opacity: 70%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 2rem;
  }

  &__today {
    color: rgba(0, 0, 0, 0.5);
    text-align: right;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }

  &__time {
    color: #000;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }

  &__icons {
    display: flex;
    width: 0.375rem;
    height: 0.5625rem;
    gap: 0.5rem;
    transform: translateX(-.1rem);
  }
}

