@import "../../styles/partials/_breakpoints.scss";

.home {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;

  &__right-side {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &--bottom {
      display: flex;
      gap: 2rem;
      margin-bottom: 2rem;

      @include lg {
      }

      @include xl {
      }
    }
  }
}
