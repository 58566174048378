@import "../../styles/partials/_breakpoints.scss";

.calendar {
  border-radius: 1.6875rem;
  background: rgba(255, 255, 255, 0.57);
  display: flex;

  @include lg {
    width: 87%;
    padding: 1rem;
  }

  @include xl {
    width: 100%;
    height: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    margin-left: 1.06rem;
  }

  &__title {
    color: #fff;
    font-size: 1.5rem;
    font-style: normal;
    padding-left: 2rem;
    display: flex;
    align-items: center;
    border-radius: 1.6875rem;
    background: linear-gradient(
      90deg,
      #1870d8 0%,
      #359af6 47.92%,
      #97bef6 89.58%
    );

    @include lg {
      width: 19rem;
      height: 3rem;
    }

    @include xl {
      font-size: 1.5rem;
      width: 30rem;
      height: 3rem;
    }
  }

  &__meetings {
    font-style: normal;
    color: #fff;
    font-weight: 400;
    line-height: normal;

    @include lg {
      font-size: 1.1rem;
      margin: 1rem;
    }

    @include xl {
      font-size: 1.2rem;
    }

    &-container {
      
      @include lg {
      }

      @include xl {
        margin-left: 3rem;
      }
    }
  }
}

.css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  color: #557feb;
  font-size: 1rem;
  font-weight: 700;
  border: none;
}

.css-qa7bje-MuiButtonBase-root-MuiPickersDay-root {
  color: white;
  font-weight: 700;
  font-size: 1rem;
}

.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
  color: white;
  font-weight: 700;
  font-size: 1rem;
}

.css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon {
  fill: white;
}

.css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
  color: white;
}

.css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
  color: white;
}

.css-cyfsxc-MuiPickersCalendarHeader-labelContainer {
  font-weight: 700;
  color: #fff;
}

.css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  font-weight: 700;
  font-size: 1rem;
  background-color: #1870d8;
}
