.bio {
  display: flex;
  align-items: flex-start;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70rem;
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.25);
  background-size: 49rem;
  //   background-position: -4rem -34rem;  //img 1
  //   background-image: url("../../assets/thingy.jpeg"); //img 1
  //   background-image: url("../../assets/img2.jpeg"); //img 2
  //   background-position: -2rem -25rem; //img 2
  //   background-image: url("../../assets/img3.jpeg"); //img 3
  //   background-position: -6rem -7rem; //img 3
  //   background-image: url("../../assets/thingy.jpeg"); //img 1 opt 2
  //   background-position: -1rem -10rem;  //img 1 opt 2
    background-image: url("../../assets/img6.JPEG"); //img 6
    background-position: -5rem -18rem; //img 6
//   background-image: url("../../assets/img4.jpeg"); //img 4
//   background-position: -5rem -18rem; //img 4

  &__who {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 23rem; // img  1
    margin-top: 3rem; // img 1
    // margin-left: 6rem; // img 2
    // margin-top: 3rem; // img 2
    // margin-left: 0rem; // img  4
    // margin-right: 0rem;
    // margin-top: auto; // img 4
    // margin-bottom: auto;
    margin: auto auto 1rem auto;
  }

  &__hey {
    color: white;
    font-weight: 400;
    margin: 0;
    transform: translateY(1rem);
  }

  &__heading {
    font-size: 5rem;
    font-weight: 700;
    margin: 0;
    color: white;
  }

  &__subheading {
    font-size: 1.5rem;
    margin: 0;
    color: black;
    text-align: center;
  }

  &__change {
    color: white;
  }
}

.about {
  &__content {
    overflow: auto;
  }

  &__header {
    margin: 2rem 4rem 0rem 4rem;
  }

  &__bio {
    @extend .about__header;
    margin-top: 0.5rem;
  }
}

.contact {
  padding: 0rem 2rem 2rem 4rem;

  &__header {
    margin-bottom: 0;
  }
}

.footer {
  background-color: #efefef;
  border-radius: 0rem 0rem 0rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
