body {
  margin: 0;
  padding: 0;
//   background: linear-gradient(180deg, #359af7 0%, #1072ba 100%);
  background-image: url('./assets/desktopimage/IMG_7566.jpeg');
  background-size: cover;
  font-family: Tahoma, Verdana, sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
