@mixin sm {
    @media only screen and (max-width: 480px) {
        @content;
    }
}

@mixin md {
    @media only screen and (min-width: 481px) and (max-width: 1024px) {
        @content;
    }
}

@mixin lg {
    @media only screen and (min-width: 1025px) and (max-width: 1599px) {
        @content;
    }
}

@mixin xl {
    @media only screen and (min-width: 1600px) {
        @content;
    }
}