@import "../../styles/partials/_breakpoints.scss";

.weather {
  border-radius: 1.6875rem;
  background: rgba(255, 255, 255, 0.57);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 0.75rem;
  padding: 1rem;

  @include lg {
    height: 12rem;
  }

  @include xl {
    width: 20rem;
    height: 15rem;
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include lg {
      margin-top: 0rem;
    }
    
    @include xl {
      margin-top: 2rem;
    }

    &__icon {

      @include lg {
        width: 3.5rem;
      }
      
      @include xl {
        width: 4rem;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      

    
    }

    &__box {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__geo {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    &__temp {
      color: #fff;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;

      @include lg {
        margin-top: 0rem;
        font-size: 3.5rem;

      }
      
      @include xl {
        font-size: 4rem;

      }
    }

    &__location,
    &__details {
      color: #fff;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }
  }

  &--loading {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    color: white;
    font-size: large;
    font-weight: 700;
  }
}
