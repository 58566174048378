@import "../../styles/partials/_breakpoints.scss";

.music {
  border-radius: 1.6875rem;
  background: rgba(255, 255, 255, 0.57);
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;

  @include lg {
    width: 25rem;
    height: 12rem;
  }

  @include xl {
    width: 32rem;
    height: 15rem;
  }

  &__details {
    display: flex;
    align-items: center;
    margin-right: 1.63rem;
    gap: 1rem;
  }

  &__player {
    margin-left: 1.63rem;
  
    @include xl {
      margin-top: 0.5rem;
    }
  }

  &__cover {
    border-radius: 1.6875rem;

    @include lg {
      width: 8.5rem;
      height: 8.5em;
      margin-left: 1.63rem;
      margin-top: 1.06rem;
    }
    @include xl {
      width: 10.25rem;
      height: 10.25rem;
      margin-left: 1.63rem;
      margin-top: 1.06rem;
    }
  }

  &__box {
    display: flex;
  }

  &__container {
    margin-top: 3rem;
  }

  &__song {
    color: #fff;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.5rem;
    margin-top: 0;

    @include lg {
      font-size: 1rem;
    }

    @include xl {
      font-size: 1.5rem;
    }

    &-details {
      color: #fff;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 0;

      @include lg {
        font-size: 1rem;
      }

      @include xl {
        font-size: 1.2rem;
      }

      &--fav {
        margin-bottom: 0;
      }
    }
  }
}

.controls {
  display: flex;

  &__button {
    background-color: transparent;
    border: none;
  }

  &__progress {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__time {
    color: #fff;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.progress-bar-container {
  height: 0.25rem;
  flex-shrink: 0;
  border-radius: 0.125rem;
  background: #dadada;
  overflow: hidden;

  @include lg {
    width: 12rem;
  }

  @include xl {
    width: 18rem;
  }
}

.progress-bar {
  height: 100%;
  background-color: #fff;
}
