
.skills {
    margin-top: 2rem;
    background-color: #efefef;
    height: fit-content;

  
    &__header {
      padding: 2rem 4rem 0rem 4rem;
      margin-top: 0rem;
      margin-bottom: 1rem;
    }
  
    &__bio {
        margin: 2rem 4rem 0rem 4rem;
    }
  
    &__container {
      justify-content: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
      padding: 0rem 4rem 2rem 4rem;
    }
  
    &__box{
      display: flex;
      background-color: white;
      border-radius: 1rem;
      align-items: center;
      justify-content: center;
      width: 6rem;
      height: 6rem;
      box-shadow: 0px 2px 25px 5px rgba(0, 0, 0, 0.15);
    }
  
    &__icons {
      width: 3rem;
      height: 3rem;
  
      &--stretch {
          height: 3.5rem;
      }
  
      &--shrink {
          height: 2.5rem;
      }
    }
  }
  